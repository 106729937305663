import {ReactCompareSlider, ReactCompareSliderImage} from "react-compare-slider";
import React from "react";
import {Card, CardActionArea, CardContent, Typography} from "@mui/material";

interface PaintingSliderProps {
    title: string;
    painting_src: string;
    drawing_src: string;
}

const PaintingSlider = (props: PaintingSliderProps) => {
    return (
        <Card>
            <CardActionArea>
                <ReactCompareSlider
                    boundsPadding={0}
                    itemOne={<ReactCompareSliderImage alt={`${props.title} Painting`} src={props.painting_src}
                                                      style={{objectPosition: 'center top'}}/>}
                    itemTwo={<ReactCompareSliderImage alt={`${props.title} Drawing`} src={props.drawing_src}
                                                      style={{objectPosition: 'center top'}}/>}
                    position={60}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                />
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        An oil painting over Sasha's drawing on canvas. 16 x 22 Cm.
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>);

}

export default PaintingSlider;