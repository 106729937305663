import React, {Fragment} from 'react'
import {Outlet} from "react-router-dom";
import {CssBaseline} from "@mui/material";


const Layout = () => {

    return (<Fragment>
            <CssBaseline/>
            <Outlet/>
        </Fragment>
    );
}

export default Layout;
