import React from 'react';
import Grid from '@mui/material/Unstable_Grid2'
import {Card, CardContent, Typography} from "@mui/material";
import zedka_painting from "../paintings/zedka_painting.png";
import zedka_drawing from "../paintings/zedka_drawing.png";
import arcencielgriffemetal_painting from "../paintings/arcencielgriffemetal_painting.png";
import arcencielgriffemetal_drawing from "../paintings/arcencielgriffemetal_drawing.png";
import cauchemarenoble_painting from "../paintings/cauchemarenoble_painting.png";
import cauchemarenoble_drawing from "../paintings/cauchemarenoble_drawing.png";
import gribouille_painting from "../paintings/gribouille_painting.png";
import gribouille_drawing from "../paintings/gribouille_drawing.png";
import phantomcauchemar_painting from "../paintings/phantomcauchemar_painting.png";
import phantomcauchemar_drawing from "../paintings/phantomcauchemar_drawing.png";
import pipivol_painting from "../paintings/pipivol_painting.png";
import pipivol_drawing from "../paintings/pipivol_drawing.png";
import gigagigaroimonstrevolcan_painting from "../paintings/gigagigaroimonstrevolcan_painting.png";
import gigagigaroimonstrevolcan_drawing from "../paintings/gigagigaroimonstrevolcan_drawing.png";
import jigadraufeuxxl_painting from "../paintings/jigadraufeuxxl_painting.png";
import jigadraufeuxxl_drawing from "../paintings/jigadraufeuxxl_drawing.png";
import roipikatu_painting from "../paintings/roipikatu_painting.png";
import roipikatu_drawing from "../paintings/roipikatu_drawing.png";
import roiplokilte_painting from "../paintings/roiplokilte_painting.png";
import roiplokilte_drawing from "../paintings/roiplokilte_drawing.png";
import PaintingSlider from "./PaintingSlider";


function Home() {
    return (
        <Grid container spacing={0} sx={{flexGrow: 0}}>
            <Grid xs={12}>
                <Card sx={{margin: "1rem", marginBottom:0}}>
                    <CardContent sx={{flex: '1 0 auto'}}>
                        <Typography component="div" variant="h2">
                            Sasha's imaginary Pokémons
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div">
                            Here we will tell the story behind our paintings. Meanwhile, please, take a
                            look at <a href="https://objkt.com/profile/banzaitokyo/created"> our profile
                            on Objkt.com
                        </a> </Typography>
                    </CardContent>
                </Card>
                <Typography variant="h1" gutterBottom>
                </Typography>
            </Grid>

            <Grid xs={12} sm={6} sx={{padding: '1rem'}}>
                <PaintingSlider title="Jigadraufeuxxl" painting_src={jigadraufeuxxl_painting} drawing_src={jigadraufeuxxl_drawing}/>
            </Grid>
            <Grid xs={12} sm={6} sx={{padding: '1rem'}}>
                <PaintingSlider title="Gigagigaroimonstrevolcan" painting_src={gigagigaroimonstrevolcan_painting}
                                drawing_src={gigagigaroimonstrevolcan_drawing}/>
            </Grid>

            <Grid xs={12} sm={6} sx={{padding: '1rem'}}>
                <PaintingSlider title="Roipikatu" painting_src={roipikatu_painting} drawing_src={roipikatu_drawing}/>
            </Grid>
            <Grid xs={12} sm={6} sx={{padding: '1rem'}}>
                <PaintingSlider title="Roiplokilte" painting_src={roiplokilte_painting}
                                drawing_src={roiplokilte_drawing}/>
            </Grid>

            <Grid xs={12} sm={6} sx={{padding: '1rem'}}>
                <PaintingSlider title="Zedka" painting_src={zedka_painting} drawing_src={zedka_drawing}/>
            </Grid>
            <Grid xs={12} sm={6} sx={{padding: '1rem'}}>
                <PaintingSlider title="Arcencielgriffemetal" painting_src={arcencielgriffemetal_painting}
                                drawing_src={arcencielgriffemetal_drawing}/>
            </Grid>

            <Grid xs={12} sm={6} sx={{padding: '1rem'}}>
                <PaintingSlider title="CauchemareNoble" painting_src={cauchemarenoble_painting}
                                drawing_src={cauchemarenoble_drawing}/>
            </Grid>
            <Grid xs={12} sm={6} sx={{padding: '1rem'}}>
                <PaintingSlider title="Gribouille" painting_src={gribouille_painting} drawing_src={gribouille_drawing}/>
            </Grid>

            <Grid xs={12} sm={6} sx={{padding: '1rem'}}>
                <PaintingSlider title="Phantomcauchemar" painting_src={phantomcauchemar_painting}
                                drawing_src={phantomcauchemar_drawing}/>
            </Grid>
            <Grid xs={12} sm={6} sx={{padding: '1rem'}}>
                <PaintingSlider title="Pipivol" painting_src={pipivol_painting} drawing_src={pipivol_drawing}/>
            </Grid>

        </Grid>
    );
}

export default Home;